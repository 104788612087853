import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/GeoProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/NextIntlClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/StatsigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/UiProvider.tsx");
