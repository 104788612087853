'use client';

import { type ReactNode, useRef } from 'react';
import { Provider } from 'react-redux';

import { initializeOrder } from '@/lib/order/orderSlice';
import { type AppStore, makeStore } from '@/lib/store';

export default function StoreProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore();
    storeRef.current.dispatch(initializeOrder());
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
